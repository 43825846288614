interface Company {
  name: string;
  link: string;
}

class TeleportDemo {
  private container: HTMLElement;
  private readonly password: string = '8675309';
  private readonly cookieName: string = 'TELEPORT-DEMO-AUTH';

  constructor () {
    document.addEventListener("DOMContentLoaded", this.init.bind(this));
    this.container = document.body.querySelector('.container');
  }

  private get companies (): Company[] {
    return [
      { name: 'anjan', link: '/anjan' },
      { name: 'booglebollywood', link: '/booglebollywood'},
      { name: 'mhone', link: '/mhone' },
      { name: 'ndtv', link: '/ndtv' },
      { name: 'nw18', link: '/nw18' },
      { name: 'republicworld', link: '/republicworld'},
      { name: 'match', link: '/match'},
      { name: 'sonyliv', link: '/sonyliv'},
      { name: 'muvi', link: '/muvi'},
      { name: 'universal', link: '/universal'},
    ];
  }

  private get template (): string {
    return '<a href="#href#"><div class="card">#name#</div></a>'
  }

  private render (): void {
    this.companies.map(company => {
      const t = this.template.replace('#href#', company.link).replace('#name#', company.name);
      this.container.innerHTML += t;
    })
  }

  private setCookie (name:string, value, options): void {
    options = options || {};

    var expires = options.expires;

    if (typeof expires == "number" && expires) {
      var d = new Date();
      d.setTime(d.getTime() + expires * 1000);
      expires = options.expires = d;
    }
    if (expires && expires.toUTCString) {
      options.expires = expires.toUTCString();
    }

    value = encodeURIComponent(value);

    var updatedCookie = name + "=" + value;

    for (var propName in options) {
      updatedCookie += "; " + propName;
      var propValue = options[propName];
      if (propValue !== true) {
        updatedCookie += "=" + propValue;
      }
    }

    document.cookie = updatedCookie;
  }

  private getCookie (name) {
    var matches = document.cookie.match(new RegExp(
      "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }

  private init (): void {
    if (this.getCookie(this.cookieName) === undefined) {
      const password = prompt('Enter a password');
      if (null === password || password !== this.password) {
        return;
      }

      this.setCookie(this.cookieName, 1, {});
    }

    this.render();
  }
}

const teleportDemo = new TeleportDemo();
